export default [
  {
    path: 'emergency-contraception',
    name: 'pharmacy-emergency-contraception',
    component: () => import('../components/patient/scripts/EmergencyContraceptionPaused.vue'),
  },
  {
    path: 'emergency-contraception/:scriptId',
    name: 'emergency-contraception-edit',
    component: () => import('../components/patient/scripts/EmergencyContraceptionPaused.vue'),
    props: true
  },
  {
    path: 'script-delivery',
    name: 'pharmacy-script-delivery',
    component: () => import('../components/patient/scripts/ShopScriptDeliveryPaused.vue'),
  },
  {
    path: 'script-delivery/:scriptId',
    name: 'pharmacy-script-delivery-edit',
    component: () => import('../components/patient/scripts/ShopScriptDeliveryPaused.vue'),
    props: true
  }, {
    path: 'script-delivery/:scriptId/cancel',
    name: 'pharmacy-request-aborted',
    component: () => import('../components/patient/SubscriptionAborted'),
    props: true
  }, {
    path: 'script-delivery/:scriptId/declined',
    name: 'pharmacy-quote-declined',
    component: () => import('../components/patient/SubscriptionAborted'),
    props: true
  },
  {
    path: 'script-checkout/:scriptId',
    name: 'pharmacy-script-checkout',
    component: () => import('../components/patient/scripts/ShopSubscriptionCheckout.vue'),
    props: true
  },
  {
    path: 'request-success',
    name: 'request-successful',
    component: () => import('../components/admin/scripts/requestsuccess'),
  },
  {
    path: 'script-subscription/:scriptId',
    name: 'pharmacy-script-subscription',
    component: () => import('../components/patient/scripts/PharmacyRequestSummary.vue'),
    props: true
  },
  {
    path: 'emergency-request/:scriptId',
    name: 'emergency-contraceptive-summary',
    component: () => import('../components/patient/scripts/PharmacyRequestSummary.vue'),
    props: true
  },

]
