export default [
  {
    path: 'dashboard', alias: 'index',
    name: 'patient-dashboard',
    component: () => import('../components/patient/Index'),
    },{
    path: 'profile',
    redirect: 'patient/dashboard',
    name: 'patient-profile',
    component: () => import('../components/patient/Profile'),
  },
  {
    path: 'script-delivery/:scriptId',
    name: 'known-script-delivery',
    component: () => import('../components/patient/scripts/ShopScriptDeliveryPaused.vue'),
    props: true
  },
  {
    path: 'consult/:bookingCode/review',
    name: 'consult-review',
    component: () => import('../components/patient/reviews/ConsultReview.vue'),
    props: true
  },
  {
    path: 'provider-review/:providerId',
    name: 'provider-review',
    component: () => import('../components/patient/reviews/ConsultReview.vue'),
    props: true
  },
  {
    path: 'view-booking/:bookingCode',
    name: 'view-booking',
    component: () => import('../components/patient/ViewBooking'),
    props: true
  },{
    path: 'pay-for-booking/:bookingCode',
    name: 'checkout-booking-alt',
    component: () => import('../components/patient/BookingPayment'),
    props: true
  },{
    path: 'consult/:bookingCode/pay',
    name: 'checkout-booking',
    component: () => import('../components/patient/BookingPayment'),
    props: true
  },{
    path: 'reschedule-booking/:bookingCode',
    name: 'known-reschedule-booking',
    component: () => import('../components/patient/RescheduleBooking'),
    props: true
  },
  {
    path: 'reschedule-booking',
    name: 'reschedule-booking',
    component: () => import('../components/patient/RescheduleBooking'),
    props: true
  },
  {
    path: 'scripts/:scriptId/view',
    name: 'view-patient-script',
    component: () => import('../components/patient/ViewScript'),
    props: true
  },
  {
    path: 'script-delivery',
    name: 'script-delivery',
    component: () => import('../components/patient/scripts/ShopScriptDeliveryPaused'),
  }, {
    path: 'subscribed-booking/:scriptId',
    name: 'known-subscribed-booking',
    component: () => import('../components/patient/scripts/ShopScriptDeliveryPaused.vue'),
    props: true
  }, {
    path: 'subscribed-booking',
    name: 'subscribed-booking',
    component: () => import('../components/patient/scripts/ShopScriptDeliveryPaused.vue'),
  }, {
    path: 'script-subscription/:scriptId/success',
    name: 'subscription-success',
    component: () => import('../components/patient/SubscriptionSuccess'),
    props: true
  }, {
    path: 'thank-you/:actionId',
    name: 'thank-you',
    component: () => import('../components/patient/ActionSuccess'),
    props: true
  }, {
    path: 'script-subscription/:scriptId/cancel',
    name: 'subscription-aborted',
    component: () => import('../components/patient/SubscriptionAborted'),
    props: true
  }, {
    path: 'script-subscription/:scriptId',
    name: 'script-subscription',
    component: () => import('../components/patient/ScriptSubscription'),
    props: true
  },{
    path: 'featured-consult',
    name: 'general-consult',
    component: () => import('../components/doctor/FeaturedConsult')
  },
  {
    path: 'category-consult',
    name: 'category-consult',
    component: () => import('../components/doctor/ConsultCategory'),
  },
  {
    path: 'category-consult/:categoryProp',
    name: 'featured-consult',
    component: () => import('../components/doctor/FeaturedConsult'),
    props: true
  },
  {
    path: 'auto-sign/:email',
    name: 'auto-signin',
    props: true,
    component: () => import('../components/patient/SearchProvider')
  },
  {
    path: 'consult/auto-sign/:bookingCode',
    name: 'auto-signin-consult',
    props: true,
    component: () => import('../components/patient/SearchProvider')
  },
  {
    path: 'fertility-consult',
    name: 'fertility-consult',
    component: () => import('../components/patient/MakeABooking')
  },
  {
    path: 'home-contraceptive',
    name: 'home-contraceptive',
    component: () => import('../components/patient/MakeABooking')
  },
  {
    path: 'booking/:providerId',
    name: 'booking',
    component: () => import('../components/patient/Booking'),
    props: true
  },
  {
    path: 'script-renewal/:scriptId',
    name: 'script-renewal-known',
    component: () => import('../components/patient/ScriptRenewal'),
    props: true
  },
  {
    path: 'script/:scriptId/renew',
    name: 'script-renew-known',
    component: () => import('../components/patient/ScriptRenewal'),
    props: true
  },
  {
    path: 'script-renewal',
    name: 'script-renewal',
    component: () => import('../components/patient/ScriptRenewal')
  },
  {
    path: 'script/renew',
    name: 'script-renew',
    component: () => import('../components/patient/ScriptRenewal')
  },
  {
    path: 'provider-consult',
    name: 'provider-consult',
    component: () => import('../components/patient/SearchProvider')
  },
]
